import getPosts from "../hook/getPosts.ts";

const postsMapperService = {
  mapPosts: async () => {
    const apiData = await getPosts();
    // Transform the API data to the desired format
    const posts = apiData.map(
      (post: {
        id: any;
        title: { rendered: any };
        _links: { self: { href: any }[] };
        content: { rendered: string };
        date: string | number | Date;
      }) => ({
        id: post.id || 0,
        title: post.title.rendered || "",
        href: post._links.self[0].href || "",
        description: post.content.rendered.replace(/<[^>]*>/g, "") || "", // Remove any HTML tags from description
        date:
          new Date(post.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric"
          }) || "" // Format the date
      })
    );
    return posts;
  }
};

export default postsMapperService;
