import React from "react";

const keyStakeholder = [
  {
    name: "Joe S",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/JoeS.png"
  },
  {
    name: "Katherine",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/katherine.png"
  },
  {
    name: "Hermione",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/hermione.png"
  }
];

const people = [
  {
    name: "Dave",
    role: "Project Lead",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/dave.png"
  },
  {
    name: "David",
    role: "Lead Software Engineer",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/david.png"
  },
  {
    name: "Tyler",
    role: "Software Engineer",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/tyler.png"
  },
  {
    name: "Jian",
    role: "Software Engineer",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/jian.png"
  },
  {
    name: "Lumière",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/lumie%CC%80re.png"
  },
  {
    name: "Yukio",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/noprofile.png"
  },
  {
    name: "SL",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/noprofile.png"
  },
  {
    name: "Ryan A",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/noprofile.png"
  },
  {
    name: "Julio",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/noprofile.png"
  }
  // More people...
];

export default function OurTeams() {
  return (
    <div className="pt-24 sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Innovators at Work
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We are a dedicated team of IT professionals committed to
            revolutionizing public service through smart technology. Our team
            comprises IT professionals in software development, data analytics,
            cybersecurity, and user experience design, all working together to
            create innovative solutions that enhance government operations and
            improve citizen engagement.
          </p>
        </div>
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
          Leading Agency / Key Stakeholder
        </h2>
        <ul
          role="list"
          className="mx-auto mt-10 grid max-w-2xl grid-cols-3 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
        >
          {keyStakeholder.map((keyStakeholder) => (
            <li key={keyStakeholder.name}>
              <img
                className="mx-auto h-24 w-24 rounded-full"
                src={keyStakeholder.imageUrl}
                alt=""
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                {keyStakeholder.name}
              </h3>
              {/* <p className="text-sm leading-6 text-gray-600">{person.role}</p> */}
            </li>
          ))}
        </ul>
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
          Engineer
        </h2>
        <ul
          role="list"
          className="mx-auto mt-10 grid max-w-2xl grid-cols-3 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
        >
          {people.map((person) => (
            <li key={person.name}>
              <img
                className="mx-auto h-24 w-24 rounded-full"
                src={person.imageUrl}
                alt={person.name}
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="text-sm leading-6 text-gray-600">{person.role}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
