import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/navbar.tsx";
import Header from "../../components/Header/header.tsx";
import ProductList from "../../components/ProductList/productList.tsx";
import Footer from "../../components/Footer/footer.tsx";

const ProjectHeader: React.FC = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain grayscale filter hover:grayscale-0 lg:col-span-1"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/1.svg"
            alt="Go.NUG"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain grayscale filter hover:grayscale-0 lg:col-span-1"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/2.svg"
            alt="Edu.NUG"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain grayscale filter hover:grayscale-0 lg:col-span-1"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/6.svg"
            alt="Metafind"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain grayscale filter hover:grayscale-0 sm:col-start-2 lg:col-span-1"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/3.svg"
            alt="Ask.NUG"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain grayscale filter hover:grayscale-0 sm:col-start-1 lg:col-span-1"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/4.svg"
            alt="PostMaster"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain grayscale filter hover:grayscale-0 sm:col-start-2 lg:col-span-1"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/5.svg"
            alt="Data.NUG"
            width={158}
            height={48}
          />
          {/* <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain grayscale filter hover:grayscale-0 sm:col-start-auto lg:col-span-1"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/5.svg"
            alt="Data.NUG"
            width={158}
            height={48}
          /> */}
        </div>
        {/* <div className="mt-16 flex justify-center">
          <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
            <span className="hidden md:inline">
              Over 200 public officier use our tools.
            </span>
            <a
              href="/products/success-stories"
              className="font-semibold text-indigo-600"
            >
              <span className="absolute inset-0" aria-hidden="true" />
              Discover Our Officier Success Stories{" "}
              <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
};

const projects = [
  {
    id: 1,
    name: "Go.NUG",
    href: "https://go.nugmyanmar.org",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/go.svg",
    status: "Complete",
    desc: "Trusted short links from your government"
  },
  {
    id: 2,
    name: "Edu.NUG",
    href: "https://edu.nugmyanmar.org",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/edu.svg",
    status: "Complete",
    desc: "Trusted short links from your official education institutions"
  },
  {
    id: 3,
    name: "Metafind",
    href: "https://metafind.nugmyanmar.org",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/metafind.svg",
    status: "Complete",
    desc: "Search engine for ctizens to find information from NUG websites."
  },
  {
    id: 4,
    name: "Ask.NUG",
    href: "https://ask.nugmyanmar.org/",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/ask.svg",
    status: "Complete",
    desc: "This tool allows NUG public officers to quickly ask questions to powerful AI models."
  },
  {
    id: 5,
    name: "Data.NUG",
    href: "https://data.nugmyanmar.org/",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/data.svg",
    status: "Complete",
    desc: "All data related to NUG public officers."
  },
  {
    id: 6,
    name: "PostMaster",
    href: "",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/postmaster.svg",
    status: "Complete",
    desc: "Mass email sending tool for NUG public officers."
  },
  {
    id: 7,
    name: "Meta Mask",
    href: "https://metamask.nugmyanmar.org/",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/metamask.svg",
    status: "Pilot Testing",
    desc: "Meta Mask VPN for NUG public officers."
  },
  {
    id: 8,
    name: "Certify",
    href: "",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/logo1x/Certify%20(1).svg",
    status: "In progress",
    desc: "Certify your documents with NUG."
  },
  {
    id: 9,
    name: "Letter.NUG",
    href: "",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/smartgov-l.svg",
    status: "In progress",
    desc: "Coming soon..."
  },
  {
    id: 10,
    name: "Form.NUG",
    href: "",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/smartgov-l.svg",
    status: "In progress",
    desc: "Coming soon..."
  },
  {
    id: 11,
    name: "FederalPass",
    href: "",
    logo: "https://cdn.egov.nugmyanmar.org/assets/smartgov/smartgov-l.svg",
    status: "In progress",
    desc: "Coming soon..."
  },
  // {
  //     id: 7,
  //     name: 'Marketing site redesign',
  //     href: '#',
  //     status: 'Archived',
  //     desc: 'Courtney Henry',
  //     dueDate: 'June 10, 2023',
  //     dueDateTime: '2023-06-10T00:00Z',
  // },
];

export default function OurProject() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <title>Projects | SmartGov</title>
      </Helmet>
      <Navbar />
      <Header
        title="Our Products"
        description="Smart government product for our officers"
      />
      <ProductList projects={projects} />
      <ProjectHeader />
      <Footer />
    </div>
  );
}
