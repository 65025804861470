import React from "react";

const statuses = {
  Complete: "text-green-700 bg-green-50 ring-green-600/20",
  "Pilot Testing": "text-blue-800 bg-blue-50 ring-blue-600/20",
  "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
  Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20"
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductList({
  projects
}: {
  projects: Array<{
    id: number;
    name: string;
    status: string;
    desc: string;
    href?: string;
    logo?: string;
  }>;
}) {
  return (
    <div className="p-8 sm:p-24">
      <ul role="list" className="divide-y divide-gray-100">
        {projects.map((project) => (
          <li
            key={project.id}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <div className="flex w-[65%] items-center gap-x-4">
              <img
                className="h-10 w-10 flex-none rounded-lg bg-gray-100 p-1.5 ring-1 ring-gray-200/50"
                src={
                  project.logo
                    ? project.logo
                    : "https://cdn.egov.nugmyanmar.org/assets/smartgov/platform/1.svg"
                }
                alt=""
              />
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {project.name}
                  </p>
                  <p
                    className={classNames(
                      statuses[project.status],
                      "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
                    )}
                  >
                    {project.status}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>{project.desc}</p>
                </div>
              </div>
            </div>
            <div
              className={
                project.href
                  ? "flex flex-none items-center gap-x-4"
                  : "flex hidden flex-none cursor-not-allowed items-center gap-x-4 opacity-50"
              }
            >
              <a
                href={project.href}
                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                View
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
