import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const navigation = [
  { name: "Products", href: "/products" },
  { name: "News", href: "/news" },
  { name: "About Us", href: "/about" }
  // { name: 'Contact Us', href: '#' },
  // { name: 'Company', href: '#' },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    // <header className="bg-gray-900">
    //   <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
    //     <div className="flex lg:flex-1">
    //       <a href="#" className="-m-1.5 p-1.5">
    //         <span className="sr-only">Your Company</span>
    //         <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="" />
    //       </a>
    //     </div>
    //     <div className="flex lg:hidden">
    //       <button
    //         type="button"
    //         className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
    //         onClick={() => setMobileMenuOpen(true)}
    //       >
    //         <span className="sr-only">Open main menu</span>
    //         <Bars3Icon className="h-6 w-6" aria-hidden="true" />
    //       </button>
    //     </div>
    //     <div className="hidden lg:flex lg:gap-x-12">
    //       {navigation.map((item) => (
    //         <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
    //           {item.name}
    //         </a>
    //       ))}
    //     </div>
    //     <div className="hidden lg:flex lg:flex-1 lg:justify-end">
    //       <a href="#" className="text-sm font-semibold leading-6 text-white">
    //         Log in <span aria-hidden="true">&rarr;</span>
    //       </a>
    //     </div>
    //   </nav>
    //   <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
    //     <div className="fixed inset-0 z-10" />
    //     <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
    //       <div className="flex items-center justify-between">
    //         <a href="#" className="-m-1.5 p-1.5">
    //           <span className="sr-only">Your Company</span>
    //           <img
    //             className="h-8 w-auto"
    //             src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
    //             alt=""
    //           />
    //         </a>
    //         <button
    //           type="button"
    //           className="-m-2.5 rounded-md p-2.5 text-gray-400"
    //           onClick={() => setMobileMenuOpen(false)}
    //         >
    //           <span className="sr-only">Close menu</span>
    //           <XMarkIcon className="h-6 w-6" aria-hidden="true" />
    //         </button>
    //       </div>
    //       <div className="mt-6 flow-root">
    //         <div className="-my-6 divide-y divide-gray-500/25">
    //           <div className="space-y-2 py-6">
    //             {navigation.map((item) => (
    //               <a
    //                 key={item.name}
    //                 href={item.href}
    //                 className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
    //               >
    //                 {item.name}
    //               </a>
    //             ))}
    //           </div>
    //           <div className="py-6">
    //             <a
    //               href="#"
    //               className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
    //             >
    //               Log in
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </DialogPanel>
    //   </Dialog>
    // </header>

    <>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="bg-[#F0F0F0]">
          <div className="mx-auto max-w-7xl px-4 py-1 sm:px-6 lg:px-8">
            {" "}
            {/* Equivalent to fluid container */}
            <div className="mx-auto max-w-full">
              {" "}
              {/* Inner container */}
              <div className="flex items-center gap-4">
                <img
                  src="https://cdn.egov.nugmyanmar.org/assets/state/nug_state_logo.svg"
                  alt="Myanmar State Emblem"
                  className="h-6 w-8"
                ></img>
                <a
                  className="nug hypernug"
                  href="https://nugmyanmar.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="text-sm">
                    An Official Website of the National Unity Government
                  </span>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">SmartGov</span>
              <img
                className="h-8 w-auto"
                src="https://cdn.egov.nugmyanmar.org/assets/smartgov/smartgov-logo-2x-white.svg?color=indigo&shade=500"
                alt="SmartGov Logo"
              />
            </a>
            <iframe
              src="https://status.smartgov.nugmyanmar.org/badge?theme=dark"
              width="250"
              height="30"
              scrolling="no"
              className="ml-2"
              title="SmartGov Status"
            />
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-white"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="/support"
              className="text-sm font-semibold leading-6 text-white"
            >
              Support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">SmartGov</span>
                <img
                  className="h-8 w-auto"
                  src="https://cdn.egov.nugmyanmar.org/assets/smartgov/smartgov-logo-2x-white.svg"
                  alt="SmartGov Logo"
                />
              </a>
              <iframe
                src="https://status.smartgov.nugmyanmar.org/badge?theme=dark"
                width="250"
                height="30"
                scrolling="no"
                className="ml-2"
                title="SmartGov Status"
              />
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="/support"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                  >
                    Support
                  </a>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </>
  );
}
