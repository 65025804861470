import getPost from "../hook/getPost.ts";

const postMapperService = {
  mapPost: async (id: number) => {
    const apiData = await getPost(id);
    // Transform the API data to the desired format
    const post = {
      id: apiData.id || 0,
      title: apiData.title.rendered || "",
      href: apiData._links.self[0].href || "",
      description: apiData.content.rendered || "",
      date:
        new Date(apiData.date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
        }) || ""
    };
    return post;
  }
};

export default postMapperService;
