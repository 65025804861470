import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/navbar.tsx";
import PostHeader from "../../components/PostHeader/postHeader.tsx";
import Footer from "../../components/Footer/footer.tsx";
import postMapperService from "../../mapper/postMapper.ts";
import { useParams } from "react-router-dom";
import "./detailPost.css";
import HeaderLoading from "../../components/PostHeaderLoading/postHeaderLoading.tsx";
import Loading from "../../components/Loading/loading.tsx";

// Define a type for the post to ensure type safety
type Post = {
  id: number;
  title: string;
  href: string;
  description: string;
  date: string;
};

export default function BlogDetail() {
  const [post, setPost] = useState(null as Post | null);

  const { id } = useParams<{ id: string }>(); // Step 2: Extract the id parameter

  useEffect(() => {
    if (id) {
      postMapperService.mapPost(parseInt(id)).then((mappedPost) => {
        // Ensure mapPost is designed to handle a single post
        setPost(mappedPost);
      });
    }
  }, [id]); // Add id as a dependency

  if (!post) return <Loading />; // Handle loading state

  return (
    <>
      <Helmet>
        <title>{post.title.toString()} | SmartGov</title>
      </Helmet>
      <Navbar />
      <PostHeader
        title={post.title.toString()}
        description={post.date.toString()}
      />

      <div className="bg-white p-0 sm:p-24">
        <div className="mx-auto px-6 lg:px-8">
          <article className="flex flex-col items-start justify-between">
            <div
              className="customHtmlContentStyle my-5 w-full leading-6"
              dangerouslySetInnerHTML={{ __html: post.description }}
            />
          </article>
        </div>
      </div>

      <Footer />
    </>
  );
}
