import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import "./tailwind.css";
// import App from './App';
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home/home.tsx";
import Support from "./pages/Support/support.tsx";
import OurProject from "./pages/Projects/projects.tsx";
import NotFoundPage from "./pages/NotFoundPage/notFoundPage.tsx"; // Ensure you have a NotFoundPage component
import Blog from "./pages/Blog/blog.tsx";
import BlogDetail from "./pages/DetailPost/detailPost.tsx";
import About from "./pages/About/about.tsx";
import SuccessStories from "./pages/SuccessStories/successStories.tsx";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacyPolicy.tsx";
import MetaMaskPolicy from "./pages/MetaMaskPolicy/metaMaskPolicy.tsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/products" element={<OurProject />} />
        <Route path="/products/success-stories" element={<SuccessStories />} />
        <Route path="/news" element={<Blog />} />
        <Route path="/news/:id" element={<BlogDetail />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/metamask/policy" element={<MetaMaskPolicy />} />
        <Route path="*" element={<NotFoundPage />} />
        {/* Add more routes as needed */}
        {/* <Route path="/other" element={<OtherComponent />} /> */}
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
