import axios from "axios";

export default async function getPosts() {
  try {
    const response = await axios.get(
      "https://admin-smartgov.nugmyanmar.org/smartapi/api/blogposts/"
    );
    const data = response.data;
    // Process the retrieved data here
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
}
