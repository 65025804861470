import {
  BuildingOfficeIcon,
  SparklesIcon,
  ShieldCheckIcon,
  DocumentMinusIcon,
  ReceiptRefundIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import React from "react";

const actions = [
  {
    title: "Smart Government Infrastructure",
    desc: "Providing innovative and secure solutions that empower the government sector to better serve the citizens.",
    icon: BuildingOfficeIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50"
  },
  {
    title: "More Smarter Solutions",
    desc: "At SmartGov, we offer a diverse range of smart solutions tailored specifically for the government sector. Our technology is designed to streamline processes, enhance service delivery, and foster a more connected and responsive government.",
    icon: SparklesIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50"
  },
  {
    title: "Privacy First",
    desc: "We prioritize user privacy and data protection above all else. Our platform is built to uphold the highest standards of security and confidentiality, ensuring that sensitive information is always protected.",
    icon: ShieldCheckIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50"
  },
  {
    title: "No More Paperwork",
    desc: "Say goodbye to cumbersome paperwork. SmartGov introduces paperless solutions that not only reduce the environmental impact but also increase operational efficiency. By digitizing processes, we help government agencies save time and resources, allowing them to focus on more important tasks.",
    icon: DocumentMinusIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50"
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ValueList() {
  return (
    <div className="my-24 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
      {actions.map((action, actionIdx) => (
        <div
          key={action.title}
          className={classNames(
            actionIdx === 0
              ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
              : "",
            actionIdx === 1 ? "sm:rounded-tr-lg" : "",
            actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
            actionIdx === actions.length - 1
              ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
              : "",
            "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
          )}
        >
          <div>
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                "inline-flex rounded-lg p-3 ring-4 ring-white"
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {/* <a href={action.href} className="focus:outline-none"> */}
              {/* Extend touch target to entire panel */}
              <span className="absolute inset-0" aria-hidden="true" />
              {action.title}
              {/* </a> */}
            </h3>
            <p className="mt-2 text-sm text-gray-500">{action.desc}</p>
          </div>
          {/* <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span> */}
        </div>
      ))}
    </div>
  );
}
