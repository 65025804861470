import React from "react";
import Header from "../../components/Header/header.tsx";
import Testimonial from "../../components/Testimonial/testimonial.tsx";
import Navbar from "../../components/Navbar/navbar.tsx";
import Footer from "../../components/Footer/footer.tsx";

export default function successStories() {
  return (
    <div>
      <Navbar />
      <Header
        title="Success Stories"
        description="Discover how SmartGov is transforming governance through our officier success stories."
      />
      <Testimonial />
      <Footer />
    </div>
  );
}
