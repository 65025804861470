import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/navbar.tsx";
import Header from "../../components/Header/header.tsx";
import Footer from "../../components/Footer/footer.tsx";
import postsMapperService from "../../mapper/postsMapper.ts";
import Loading from "../../components/Loading/loading.tsx";

// Define a type for the post to ensure type safety
type Post = {
  id: number;
  title: string;
  href: string;
  description: string;
  date: string;
};

export default function Blog() {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    postsMapperService.mapPosts().then((mappedPosts) => {
      setPosts(mappedPosts);
    });
  }, []);

  // if (posts.length === 0) return <Loading />; // Handle loading state

  return (
    <>
      <Helmet>
        <title>News | SmartGov</title>
      </Helmet>
      <Navbar />
      {/* <Header title={"News"} description={"Latest Updates from Our Team"} /> */}
      <Header title={"Oops..."} description={"Currently Not Available"} />
      {/* <div className="mb-12 bg-white py-12 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-gray-200 pt-10 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="flex max-w-xl flex-col items-start justify-between"
              >
                <div className="flex items-center gap-x-4 text-xs">
                  <time className="text-gray-500">{post.date}</time>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 line-clamp-2 text-lg font-semibold leading-8 text-gray-900 group-hover:text-gray-600">
                    <a href={"/news/" + post.id}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                    {post.description}
                  </p>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div> */}
      {/* Loading... */}
      {/* <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center p-6 bg-white shadow-lg rounded-lg">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Currently Not Available</h1>
          <p className="text-lg text-gray-600 mb-6">We will be back soon.</p>
          <div className="flex justify-center">
            <svg
              className="animate-spin h-8 w-8 text-indigo-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </div> */}
      <div className="flex items-center justify-center min-h-96 bg-gray-100">
        <div className="text-center p-6">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Currently Not Available</h1>
          <p className="text-lg text-gray-600 mb-6">We will be back soon.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
