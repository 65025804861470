async function sendDataWithWebhook(message) {
  const url =
    "https://chat.googleapis.com/v1/spaces/AAAARd4WGDE/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=kTnamET8fDY9pZgBhnnU23X5cWhge8ywP1mDTM4Ot9I";
  const res = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    body: JSON.stringify({ text: message })
  });
  return await res.json();
}

export const postNow = async (data: {
  name: string;
  email: string;
  assist: string;
  selectedAgency: string;
  issue: any;
  officelDomain?: string;
  timestamp: any;
}) => {
  console.log(data);
  try {
    // eslint-disable-next-line no-useless-concat
    const message =
      `A new request from ${data.name} has been received.` +
      `\n` +
      `\n` +
      `Details as shown below` +
      `\n` +
      `\n` +
      `Personal Info` +
      `\n` +
      `Name: ${data.name}\nEmail: ${data.email}\n${data.selectedAgency ? `Government Agency: ${data.selectedAgency}` : ""}\n${data.officelDomain ? `Requested Domain Whitelist Requested for Educational Purpose: ${data.officelDomain}` : ""}\n\n` +
      `Assist: ${data.assist}` +
      `\n\n` +
      `Issue:` +
      `\n` +
      `${data.issue}` +
      `\n` +
      `\n` +
      `Timestamp: ${data.timestamp} Myanmar Standard Time` +
      `\n` +
      `\n` +
      `Please check the details and take necessary actions.` +
      `\n` +
      `Thank you!`;
    sendDataWithWebhook(message).then((res) => console.log(res));
    return { code: 200, message: "Successfully Submitted!" };
  } catch (err) {
    console.error(err.message);
    return { code: 400, message: err.message };
  }
};
