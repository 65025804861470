import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/header.tsx";
import Navbar from "../../components/Navbar/navbar.tsx";
import Footer from "../../components/Footer/footer.tsx";

export default function MetaMaskPolicy() {
    return (
        <>
            <Helmet>
                <title>Meta Mask Policy | SmartGov</title>
            </Helmet>
            <Navbar />
            <Header
                title="Meta Mask VPN Key Policy"
                description="M-Key Policy for Our Public Officier" />
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto lg:mx-0 lg:max-w-none">
                        {/* <div className="mx-auto lg:mx-0">
                            <h1>Meta Mask VPN Key Generation Policy</h1>

                            <h2>Purpose</h2>
                            <p>This policy outlines the use, distribution, and management of VPN keys generated by SmartGov, under the Ministry of Communication and Information Technology (MoCIT). The VPN keys ensure secure access for public officers when using government services or social media.</p>

                            <h2>Scope</h2>
                            <p>This policy applies to all public officers authorized to use the VPN keys provided by SmartGov. Unauthorized access or use of these VPN keys is strictly prohibited.</p>

                            <h2>Policy Statements</h2>

                            <h2>1. Authorized Use</h2>
                            <ul>
                                <li><strong>Eligibility</strong>: VPN keys are issued exclusively to public officers who have been authorized by their respective departments.</li>
                                <li><strong>Access Control</strong>: Each VPN key is assigned to a specific user and must not be shared. Authorized personnel are responsible for safeguarding their VPN keys.</li>
                            </ul>

                            <h2>2. Distribution and Management</h2>
                            <ul>
                                <li><strong>Issuance</strong>: VPN keys will be generated and distributed by SmartGov. Officers must submit a request through their departmental IT coordinators.</li>
                                <li><strong>Renewal and Revocation</strong>: VPN keys are valid for a specified period. They must be renewed upon expiration or as deemed necessary by SmartGov. Keys will be revoked immediately upon an officer’s termination, resignation, or unauthorized use.</li>
                            </ul>

                            <h2>3. Usage Guidelines</h2>
                            <ul>
                                <li><strong>Security Measures</strong>: Officers must use VPN keys solely for official government activities. Any personal use is prohibited.</li>
                                <li><strong>Compliance</strong>: All users must comply with MoCIT’s IT security policies and guidelines. Failure to adhere to these policies may result in disciplinary action.</li>
                            </ul>

                            <h2>4. Monitoring and Auditing</h2>
                            <ul>
                                <li><strong>Logging</strong>: All VPN access is logged and monitored to ensure compliance with this policy. Logs are reviewed regularly to detect and address any unauthorized access or suspicious activity.</li>
                                <li><strong>Audits</strong>: Periodic audits will be conducted to verify adherence to this policy and to ensure the integrity of the VPN key management system.</li>
                            </ul>

                            <h2>5. Incident Management</h2>
                            <ul>
                                <li><strong>Reporting</strong>: Any suspected compromise or misuse of VPN keys must be reported immediately to the department’s IT security team and SmartGov.</li>
                                <li><strong>Response</strong>: Incidents will be investigated promptly, and appropriate measures will be taken to mitigate risks and prevent recurrence.</li>
                            </ul>

                            <h2>Enforcement</h2>
                            <p>Non-compliance with this policy may result in disciplinary actions, including but not limited to revocation of VPN access, suspension, or termination of employment. Legal action may also be pursued in cases of severe violations.</p>

                            <h2>Review and Revision</h2>
                            <p>This policy will be reviewed annually and updated as necessary to reflect changes in technology, regulations, or organizational requirements.</p>

                            <div class="contact">
                                <p><strong>Contact Information</strong></p>
                                <p>For questions or further information regarding this policy, please contact SmartGov support at <a href="mailto:support@smartgov.mocit.gov">support@smartgov.mocit.gov</a> or your departmental IT coordinator.</p>
                            </div>

                            <p><strong>Effective Date</strong>: [Insert Date]</p>
                            <p><strong>Review Date</strong>: [Insert Date]</p>
                        </div> */}
                        <div className="mx-auto  lg:mx-0 p-6 bg-white">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-6">Meta Mask VPN Key Policy</h1>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">Objectives</h2>
                            <p className="mt-4 text-lg leading-8 text-gray-600 mb-6">
                                This policy outlines the use, distribution, and management of VPN keys generated by SmartGov, under the Ministry of Communication and Information Technology (MoCIT). The VPN keys ensure secure access for public officers when using government services or social media.
                            </p>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">Restriction</h2>
                            <p className="mt-4 text-lg leading-8 text-gray-600 mb-6">
                                This policy applies to all public officers authorized to use the VPN keys provided by SmartGov. Unauthorized access or use of these VPN keys is strictly prohibited.
                            </p>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">Policy Statements</h2>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">1. Authorized Use</h2>
                            <ul className="list-disc list-inside ml-4 text-lg text-gray-600 space-y-2">
                                <li><strong>Eligibility</strong>: VPN keys can be generated exclusively for public officers who possess an official email address issued by their respective departments.</li>
                                <li><strong>Access Control</strong>: Each VPN key is assigned to a specific user and must not be shared. Authorized personnel are responsible for safeguarding their VPN keys.</li>
                            </ul>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">2. Distribution and Management</h2>
                            <ul className="list-disc list-inside ml-4 text-lg text-gray-600 space-y-2">
                                <li><strong>Issuance</strong>: VPN keys will be generated using the Meta Mask web application and will require only an official email address for the process.</li>
                                <li><strong>Renewal and Revocation</strong>: VPN keys are valid for a specified period of 1 month, starting from the 1st day of the month and expiring at the end of the month. Keys must be renewed monthly, or as necessary, and will be revoked immediately upon an officer’s termination, resignation, or unauthorized use.</li>
                            </ul>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">3. Usage Guidelines</h2>
                            <ul className="list-disc list-inside ml-4 text-lg text-gray-600 space-y-2">
                                <li><strong>Security Measures</strong>: Officers must use VPN keys solely for official government activities. Any personal use is prohibited.</li>
                                <li><strong>Compliance</strong>: All users must comply with MoCIT’s IT security policies and guidelines. Failure to adhere to these policies may result in revocation of VPN access and other disciplinary actions.</li>
                            </ul>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">4. Monitoring and Auditing</h2>
                            <ul className="list-disc list-inside ml-4 text-lg text-gray-600 space-y-2">
                                <li><strong>Logging</strong>: Log the identities of users who generate VPN access keys to ensure compliance with this policy, but we do not log or monitor the content of your activities, such as what you access or how you use the VPN. Logs are reviewed regularly to detect and address any unauthorized access or suspicious activity.</li>
                                <li><strong>Audits</strong>: Periodic audits will be conducted to verify adherence to this policy and to ensure the integrity of the VPN key management system.</li>
                            </ul>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">5. Incident Management</h2>
                            <ul className="list-disc list-inside ml-4 text-lg text-gray-600 space-y-2">
                                <li><strong>Reporting</strong>: Any suspected compromise or misuse of VPN keys must be reported immediately to the department’s IT security team and SmartGov.</li>
                                <li><strong>Response</strong>: Incidents will be investigated promptly, and appropriate measures will be taken to mitigate risks and prevent recurrence.</li>
                            </ul>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">Enforcement</h2>
                            <p className="mt-4 text-lg leading-8 text-gray-600 mb-6">
                                Non-compliance with this policy may result in disciplinary actions, including but not limited to revocation of VPN access or suspension. Legal action may also be pursued in cases of severe violations.
                            </p>

                            <h2 className="mt-6 text-xl font-bold tracking-tight text-gray-900 mb-4">Review and Revision</h2>
                            <p className="mt-4 text-lg leading-8 text-gray-600 mb-6">
                                This policy will be reviewed annually and updated as necessary to reflect changes in technology, regulations, or organizational requirements.
                            </p>

                            <div className="contact mt-6 text-lg leading-8 text-gray-600 mb-6">
                                <p><strong>Contact Information</strong></p>
                                <p>For questions or further information regarding this policy, please contact SmartGov support at <a href="mailto:smartgov@mocitmyanmar.org" className="text-blue-600 underline">smartgov@mocitmyanmar.org</a> or your departmental IT coordinator.</p>
                            </div>

                            <p className="mt-6 text-lg leading-8 text-gray-600"><strong>Effective Date</strong>: 29 Jul 2024</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600"><strong>Review Date</strong>: 10 Aug 2024</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}