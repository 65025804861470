import React from "react";

const testimonials = [
  {
    body: `Delighted to share the experience with the smart government services that have truly revolutionized our community's interaction with public services. The inclusion of trusted short links from our government ministerial sites has significantly improved accessibility and trust. These links ensure that we are always directed to genuine and secure websites, which is crucial in today’s digital age where cyber threats are prevalent.

Moreover, the capability for government officials to ask questions to powerful AI models has enhanced the efficiency and accuracy of our services. Whether it's policy formulation or responding to any queries, the AI models provide quick and insightful answers, enabling informed decision-making.

Having access to all government numbers in one place has simplified communication, making it easier for citizens to reach the right department without any hassle. Additionally, the mass email sending tool is a game-changer for disseminating information swiftly to a large audience, ensuring everyone is informed about important updates and events regarding the current situations in Myanmar.

The integrated VPN tool adds an extra layer of security, protecting sensitive data and ensuring that our online interactions are safe and private. 
One of the most impressive features is the digital archive document search engine. This tool has transformed the way we access and manage historical and legal documents and statements published by the NUG government. With an intuitive search interface, it allows citizens and officials to quickly find and retrieve archived documents. This not only saves time but also promotes transparency and accountability by making these records easily accessible.

Overall, these smart government services provided by NUG have not only streamlined our processes but also fostered greater transparency and trust within the community.`,
    author: {
      name: "Joe S",
      handle: "MoCIT",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/JoeS.png"
    }
  },
  {
    body: `I am excited to be part of the SmartGov team, which is dedicated to creating a more efficient and transparent government. By leveraging technology, we can provide better services to the people of Myanmar and improve the overall quality of life in our country.`,
    author: {
      name: "Katherine",
      handle: "SmartGov",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/katherine.png"
    }
  },
  {
    body: `As a SmartGov team member, I'm highly motivated to leverage technology to improve government efficiency, transparency, and citizen engagement. This includes developing user-friendly platforms, analyzing data for better service delivery, and collaborating across departments to achieve these goals.`,
    author: {
      name: "Hermione",
      handle: "SmartGov",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/hermione.png"
    }
  },
  {
    body: `I am proud to share that after a decade of efforts to develop eGov in Myanmar, we are finally seeing significant progress. Despite past challenges, SmartGov is now being implemented alongside the ongoing military revolution. This initiative is set to enable the Myanmar government to offer the best e-services, leveraging modern technology to meet the needs of our citizens in this transformative era.`,
    author: {
      name: "Dave",
      handle: "SmartGov",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/dave.png"
    }
  },
  {
    body: `Transitioning from conventional government operations to a smart government model is the most effective way to serve the people through technology. By establishing a robust communication network between our government and the citizens, we can deliver services more quickly and accurately than ever before.`,
    author: {
      name: "David",
      handle: "SmartGov",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/david.png"
    }
  },
  {
    body: `Together, we are building SmartGov into a robust bridge that seamlessly links citizens with their government. I am honored to be part of this team, as it empowers me to deliver top-notch services to the people of Myanmar through cutting-edge technology.`,
    author: {
      name: "Tyler",
      handle: "SmartGov",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/tyler.png"
    }
  },
  {
    body: `I take pride in our Smart Government, which has persevered through challenges to achieve this milestone. After sustained effort, we have finally reached fruition. Our commitment now lies in delivering superior services to the public, ensuring timely and reliable information.`,
    author: {
      name: "Jian",
      handle: "SmartGov",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/jian.png"
    }
  },
  {
    body: `Despite the challenges and efforts involved, SmartGov has been successfully implemented. It is a great honor to be part of this dedicated and united team, contributing to this revolutionary period. As technology advances, our SmartGov will evolve with it, becoming an increasingly valuable resource for our community.`,
    author: {
      name: "Lumière",
      handle: "SmartGov",
      imageUrl:
        "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/lumie%CC%80re.png"
    }
  }
  // More testimonials...
];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

export default function OurTeamsSay() {
  return (
    <div className="relative isolate bg-white pt-24 sm:pt-32">
      <div
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
          }}
        />
      </div>
      <div
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
            What Our Innovators Say
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We have worked with amazing people
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.handle}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img
                      className="h-10 w-10 rounded-full bg-gray-50"
                      src={testimonial.author.imageUrl}
                      alt=""
                    />
                    <div>
                      <div className="font-semibold text-gray-900">
                        {testimonial.author.name}
                      </div>
                      <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
